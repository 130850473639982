import moment from 'moment';

const utils = {
  install(Vue) {
    Vue.prototype.coverte_para_float_valor_input = (value) => {
      let stringValor = value;
      let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));

      return valorFlutuante;
    }

    Vue.prototype.formatInputFloat = (event, parentObject, propertyName) => {
      // Obtenha o valor do campo de entrada do evento
      let value = event.target.value;

      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = value.replace(/\D/g, "");

      // Converta o valor numérico para um número de ponto flutuante
      let floatValue = parseFloat(numericValue / 100);

      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      let formattedValue = floatValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).replace("R$", " ");

      // Atualize o valor do campo de entrada com o valor formatado
      event.target.value = formattedValue;

      // Atualize o valor no objeto pai
      parentObject[propertyName] = event.target.value;
    };
    Vue.prototype.formatterCurrBR = (value) => {
      if (!parseFloat(value)) {
        return parseFloat(value).toFixed(2).replace(".", ",");
      }

      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      return value.toLocaleString("pt-BR", formatoMoeda).replace("R$", "");
    }
    Vue.prototype.new_convert_srting_float = (value) => {
      let resultado = value;

      if (typeof resultado == "string") {

        if (resultado.includes('.') && resultado.includes(',')) {

          resultado = parseFloat(resultado.replace('.', '').replace(',', '.'));

        } else if ((!resultado.includes('.')) && resultado.includes(',')) {

          resultado = parseFloat(resultado.replace(',', '.'));

        } else if ((!resultado.includes('.')) && (!resultado.includes(','))) {
          resultado = parseFloat(resultado);
        }

      } else {

        resultado = value

      }
      return resultado;
    }

    // para funcionar tenhho que passar o valor em float;
    Vue.prototype.formatterFloatBR = (value, fixed) => {
      if (!parseFloat(value)) {
        return parseFloat(value).toFixed(fixed).replace(".", ",");
      }

      const formatoMoeda = {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: fixed,
        maximumFractionDigits: fixed,
      };
      let valor = (value)
      return valor.toLocaleString("pt-BR", formatoMoeda).replace("R$", "");
    }

    Vue.prototype.removeAccent = (value) => {
      let str = value;
      str = str.replace(/[ÀÁÂÃÄÅ]/g, "A");
      str = str.replace(/[àáâãäå]/g, "a");
      str = str.replace(/[ÈÉÊË]/g, "E");
      return str;
    }

    Vue.prototype.formatDateBR = (value) => {
      return (value) ? moment(value).format('DD/MM/yyyy') : '';
    }

    Vue.prototype.formatDateTimeBR = (value) => {
      return (value) ? moment(value).format('DD/MM/yyyy HH:MM') : '';
    }


    // Truncate string limit
    Vue.prototype.truncate = (string, limit) => {
      return string.length > limit ? string.substring(0, limit) + '...' : string;
    }
  }
}

export default utils