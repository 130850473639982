import { authHeader } from './auth-header';
import Cookie from 'js-cookie';

// URI da API EasySaude
//let baseApiUrl = 'https://hl63y57qj3.execute-api.us-east-2.amazonaws.com/api/';
let baseApiUrl = process.env.VUE_APP_BASEURI + '/api/';

export const userService = {
    login,
    logout,
    register,
	resetpassword,
    getAll,
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ email, password })
    };

    return fetch(baseApiUrl + 'login', requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                user.login = new Date();
				Cookie.set('_easyindustria_token', user.token);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
	localStorage.removeItem('currentEmpresa');
    localStorage.removeItem('user');
    Cookie.remove('_easyindustria_token');
}

function resetpassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(email)
    };

    return fetch(baseApiUrl + 'password/forgot-password', requestOptions)
		.then(handleResponse)
		.then(response => {
			if (response.ok) {
				// auto logout if 200 response returned from api
				logout();
				location.reload(true);
			}
		});
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };
    return fetch(baseApiUrl + 'user/register', requestOptions).then(handleResponse);
}

function getAll(Aid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(baseApiUrl + 'empresa/usersbyempresa/' + Aid, requestOptions)
        .then(handleResponse)
        .then(users => {
            if (users) {
                // store users in local storage
                localStorage.setItem('users', JSON.stringify(users));
            }
            return users;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
