import { userService } from '../../helpers/easyindustriaapi/user.service';
import router from '../../router/index';

const currentEmpresa = (JSON.parse(localStorage.getItem('currentEmpresa'))) ? JSON.parse(localStorage.getItem('currentEmpresa')) : null;

//initial state
export const state = { status: {}, user: null, currentEmpresa: null };

export const mutations = {
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
        state.currentEmpresa = null;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
        state.currentEmpresa = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
        state.currentEmpresa = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    resetpassWordRequest(state) {
        state.status = { resetpassword: true };
    },
    resetpassWordSuccess(state) {
        state.status = {};
    },
    resetpassWordFailure(state) {
        state.status = {};
    },
    listUsersRequest(state) {
        state.users = null;
        state.status = { listed: true };
    },
    listUsersSuccess(state, users) {
        state.status = {};
        state.users = users;
    },
    listUsersFailure(state) {
        state.status = {};
        state.users = null;
    },
    selectedEmpresaRequest(state) {
        state.status = { loggingIn: true };
        state.currentEmpresa = currentEmpresa;
    },
    selectedEmpresa(state, currentEmpresa) {
        state.status = {};
        state.status = { loggeduser: true };
        state.currentEmpresa = currentEmpresa;
    },
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
};

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    loGin({ dispatch, commit }, { email, password }) {
        const promisse = userService.login(email, password);
        return promisse.then(
            user => {
                commit('loginSuccess', user);
                router.push('/select-company');
            },
            error => {
                if (error === 'Unauthorized') {
                    error = 'Login ou senha inválidos';
                }

                commit('loginFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    // Logout the user
    loGout({ commit }) {
        userService.logout();
        commit('logout');
    },
    resetpassWord({ dispatch, commit }, email) {
        commit('resetpassWordRequest', email);

        userService.resetpassword(email)
            .then(
                email => {
                    commit('resetpassWordSuccess', email);
                    dispatch('notification/success', 'Link para redefinição de senha enviado para o e-mail', { root: true });
                });
    },
    // register the user
    registerUser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        const promisse = userService.register(user);
        return promisse
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Cadastro realizado com sucesso', { root: true });
                    router.push('/userGetAll');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    getAll({ dispatch, commit }, Aid) {
        userService.getAll(Aid)
            .then(
                users => {
                    commit('listUsersSuccess', users);
                    // dispatch('notification/success', 'Lista carregada', { root: true });
                    // router.push('/userGetAll');
                },
                error => {
                    commit('listUsersFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            )
    },
    selectEmpresa({ commit }, Empresa) {
        commit('selectedEmpresaRequest', { Empresa });
        localStorage.setItem('currentEmpresa', JSON.stringify(Empresa));
        commit('selectedEmpresa', Empresa);
    },
};