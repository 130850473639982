<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

// import appConfig from "@/app.config";

export default {
  name: "app",
  
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      // title = typeof title === "function" ? title(this.$store) : title;
      // return title ? `${title} | ${appConfig.title}` : appConfig.title;
      return process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME : title;
    }
  }
};
</script>